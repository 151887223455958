"use client";
import React, { useEffect, useState } from "react";
import { useTheme } from "next-themes";
import Image from "next/image";

const ToggleMode = () => {
  const { theme, setTheme, systemTheme } = useTheme();
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    if (theme === "system") {
      setIsDarkMode(systemTheme === "dark");
    } else {
      setIsDarkMode(theme === "dark");
    }
  }, [theme, systemTheme]);

  const handleThemeToggle = () => {
    const newTheme = isDarkMode ? "light" : "dark";
    setTheme(newTheme);
    setIsDarkMode(!isDarkMode); // Actualiza el estado local
  };

  return (
    <div className="nav-link" onClick={handleThemeToggle}>
      {isDarkMode ? (
        <Image src="/icons/sun-icon.png" alt="light-mode" width={30} height={30} />
      ) : (
        <Image src="/icons/moon-icon.png" alt="dark-mode" width={30} height={30} />
      )}
    </div>
  );
};

export default ToggleMode;
