"use client";
import { useParams, useRouter, useSearchParams, usePathname } from "next/navigation";
import { useTranslations } from "next-intl";

export default function LanguageSwitch() {
  // const t = useTranslations("LocaleSwitcher");
  const locales = [
    { code: 'en', label: 'English' },
    { code: 'es', label: 'Español' },
    { code: 'it', label: 'Italiano' },
    { code: 'pt', label: 'Portuguese' },
    { code: 'fr', label: 'Français' },
    { code: 'de', label: 'Deutsch' },
  ];

  const router = useRouter();
  const searchParams = useSearchParams(); // Obtener los parámetros de búsqueda actuales
  const { locale } = useParams(); 

  const changeLocale = (newLocale: string) => {
    const paramsString = searchParams.toString();
    const newPathname = `/${newLocale}` + window.location.pathname.slice(locale.length + 1); // Cambiar el locale sin romper la ruta

    router.push(`${newPathname}?${paramsString}`);
  };
  return (
    <div>
      <select
        id="locale"
        className="form-select form-select-sm"
        onChange={(e) => changeLocale(e.target.value)}
        defaultValue={locale}
      >
        {locales.map(({ code, label }) => (
          <option key={code} value={code}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
}
