'use client'
import { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import "./_header.scss"
import NavBar from '../NavBar/NavBar';
import useWindowSize from '@/hooks/useWindowSize';
import ToggleMode from '@/components/atoms/ThemeSwitch/ThemeSwitch';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [shouldRenderMenu, setShouldRenderMenu] = useState(false);
  const [menuClass, setMenuClass] = useState('close'); // Clase de animación del menú

  const size = useWindowSize();

  const toggleMenu = () => {
    if (!isMenuOpen) {
      setShouldRenderMenu(true); // Mostrar el menú antes de abrir
      setTimeout(() => {
        setMenuClass('open'); // Añadir clase `open` para la animación de entrada
      }, 10); // Pequeño retraso para disparar la animación
    } else {
      setMenuClass('close'); // Cambiar a clase `close` para la animación de salida
    }
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (!isMenuOpen) {
      const timer = setTimeout(() => {
        setShouldRenderMenu(false); // Retira el menú del DOM solo después de la animación de salida
      }, 400); // Debe coincidir con la duración de la transición CSS
      return () => clearTimeout(timer); // Limpiar el temporizador si se desmonta antes
    }
  }, [isMenuOpen]);
  return (
    <>
      <header className="header">
        <div className="container d-flex flex-wrap justify-content-between align-items-center">
          <Link href="/" className="navbar-brand text-light">
            <Image
              className="logo logo-light"
              src="/icons/line-logo-light.svg"
              alt="Line Logo Light"
              priority={true}
              width={70}
              height={50}
            />
            <Image
              className="word-logo logo-light"
              src="/icons/footballtrends-light.svg"
              alt="Name Logo Light"
              priority={true}
              width={170}
              height={50}
            />
            <Image
              className="logo logo-dark"
              src="/icons/line-logo-dark.svg"
              alt="Line Logo Dark"
              priority={true}
              width={70}
              height={50}
            />
            <Image
              className="word-logo logo-dark"
              src="/icons/footballtrends-dark.svg"
              alt="Name Logo Dark"
              priority={true}
              width={170}
              height={50}
            />
          </Link>
          {size.width < 768 ? (
            <div className="d-flex align-items-center">
              <div className="px-3">
                <ToggleMode />
              </div>
              <div className="px-2" onClick={toggleMenu}>
                {isMenuOpen ? <span>✖</span> : <span>&#9776;</span>}
              </div>
            </div>
          ) : (
            <NavBar toggleMenu={toggleMenu} />
          )}
        </div>

        {shouldRenderMenu && size.width < 768 && (
          <div
            className={`dropdown-menu-container ${menuClass}`}
          >
            <div className='container'>
              <NavBar toggleMenu={toggleMenu} />
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
